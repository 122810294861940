<template>
  <div>
    <h4 class="workplatform-title m-t-10 m-b-10">账单基本信息</h4>
    <Row class="p-b-10">
      <i-col span="8">
        <span class="title">合同编号</span>{{ debtInfo.code }}
      </i-col>
      <i-col span="8">
        <span class="title">合同名称</span>{{ debtInfo.name }}
      </i-col>
      <i-col span="8">
        <span class="title">客户名称</span>{{ debtInfo.advertiserName }}
      </i-col>
    </Row>
    <Row class="p-b-10">
      <i-col span="8">
        <span class="title">开始日期</span>{{ debtInfo.startDate }}
      </i-col>
      <i-col span="8">
        <span class="title">结束日期</span>{{ debtInfo.endDate }}
      </i-col>
      <i-col span="8">
        <span class="title">付款日期</span>{{ debtInfo.dueDate }}
      </i-col>
    </Row>
    <Row class="p-b-10">
      <i-col span="8">
        <span class="title">总额</span>{{ debtInfo.amount }}
      </i-col>
      <i-col span="8">
        <span class="title">账单状态</span>{{ debtInfo.statusName }}
      </i-col>
      <i-col span="8">
        <span class="title">资源名称</span>{{ debtInfo.objectNames }}
      </i-col>
    </Row>

    <h4 class="workplatform-title m-t-10 m-b-10">账单明细</h4>
    <p class="p-b-10">
      <Button type="primary" size="small" :disabled="selectedIds.length===0" @click="handleBatchSubmitConfirm">批量发起确认</Button>
    </p>
    <Table size="small" ref="tableDebt" stripe :data="tableData" :columns="tableColumns"
      @on-selection-change="selectionChange"></Table>

    <Modal v-model="modalChangeMoney" width="600">
      <p slot="header" class="text-center">调整金额</p>
      <Row class="p-b-10">
        <i-col span="3"><span class="title">总额</span></i-col>
        <i-col span="9">
          {{ changeBean.objectName }}
        </i-col>
        <i-col span="3"><span class="title">开始日期</span></i-col>
        <i-col span="9">
          {{ changeBean.startDate }}
        </i-col>
      </Row>
      <Row class="p-b-10">
        <i-col span="3"><span class="title">结束日期</span></i-col>
        <i-col span="9">
          {{ changeBean.endDate }}
        </i-col>
        <i-col span="3"><span class="title">月租金</span></i-col>
        <i-col span="9">
          {{ formatMoney(changeBean.monthPrice) }}
        </i-col>
      </Row>
      <Row class="p-b-10">
        <i-col span="3"><span class="title">费用类型</span></i-col>
        <i-col span="9">
          {{ changeBean.feeTypeName }}
        </i-col>
        <i-col span="3"><span class="title">账单金额</span></i-col>
        <i-col span="9">
          {{ formatMoney(changeBean.amount) }}
        </i-col>
      </Row>

      <Row class="p-b-10">
        <i-col span="3"><span class="title">确认金额</span></i-col>
        <i-col span="21">
          <InputNumber :max="100000000" style="width: 140px;" v-model="changeBean.ackAmount"></InputNumber>
        </i-col>
      </Row>
      <Row class="p-b-10">
        <i-col span="3"><span class="title">调整原因</span></i-col>
        <i-col span="21">
          <RadioGroup v-model="changeBean.ackType">
            <Radio :label="1">政策性减免</Radio>
            <Radio :label="2">其他</Radio>
          </RadioGroup>
        </i-col>
      </Row>
      <Row class="p-b-10">
        <i-col span="3"><span class="title">备注</span></i-col>
        <i-col span="21">
          <Input v-model="changeBean.ackRemark" maxlength="500" :rows="3" show-word-limit type="textarea" placeholder="请输入调整备注信息..." style="width: 400px" />
        </i-col>
      </Row>

      <div slot="footer">
        <Button type="text" class="m-r-5" @click="modalChangeMoney = false">取消</Button>
        <Button type="primary" @click="handleSubmitChange">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getDebt, listDebtDetail, updateDebtDetailAmount, confirmDebtDetail } from '@/api/invest/debt'

export default {
  props: {},
  data () {
    return {
      debtInfo: {},
      modalChangeMoney: false,
      changeBean: {},
      selectedIds: [],

      tableData: [],
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '标的物名称', key: 'objectName' },
        { title: '开始日期', key: 'startDate' },
        { title: '结束日期', key: 'endDate' },
        {
          title: '月租金',
          key: 'amount',
          width: 120,
          render: (h, params) => {
            return h('strong', {}, toMoney(params.row.monthPrice))
          }
        },
        { title: '费用类型', key: 'feeTypeName' },
        {
          title: '账单金额',
          key: 'amount',
          width: 120,
          render: (h, params) => {
            return h('strong', {}, toMoney(params.row.amount))
          }
        },
        {
          title: '确认金额',
          key: 'amount',
          width: 120,
          render: (h, params) => {
            return h('strong', {
              attrs: {
                style: 'color:#44bd32'
              }
            },
            toMoney(params.row.ackAmount))
          }
        },
        { title: '调整原因', key: 'ackTypeName' },
        { title: '确认备注', key: 'ackRemark' },
        { title: '确认时间', key: 'ackTime' },
        {
          title: '当前状态',
          align: 'center',
          render: (h, params) => {
            if (params.row.status > 0) {
              return h('tag', { props: { color: 'cyan' } }, params.row.statusName)
            } else {
              return h('tag', { props: { color: '#3B3B3B' } }, params.row.statusName)
            }
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 100,
          render: (h, params) => {
            if (params.row.status === 0) {
              return h('a',
                {
                  on: {
                    click: () => {
                      this.handleChangeMoney(params.row)
                    }
                  }
                }, '调整金额')
            } else {
              return ''
            }
          }
        }
      ]
    }
  },
  mounted () {},
  created () {
    if (this.debtId) {
      this.initDetail()
    }
  },
  methods: {
    initDetail () {
      getDebt({ debtId: this.debtId }).then(res => {
        this.debtInfo = res
      })

      this.loadDebtDetail()
    },
    loadDebtDetail () {
      listDebtDetail({ debtId: this.debtId }).then(res => {
        this.tableData = res

        // 格式化表格，已确认的数据不能再被勾选
        this.$nextTick(() => {
          const currentTableData = this.$refs.tableDebt.$refs.tbody.objData

          for (let index = 0; index < res.length; index++) {
            if (currentTableData[index]) {
              currentTableData[index]._isDisabled = currentTableData[index].status === 1
            }
          }
        })
      })
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handleChangeMoney (params) {
      this.changeBean = Object.assign({}, params)
      this.modalChangeMoney = true
    },
    handleSubmitChange () {
      const submitBean = {
        ackType: this.changeBean.ackeType,
        amount: this.changeBean.ackAmount,
        ackRemark: this.changeBean.ackRemark,
        detailId: this.changeBean.id
      }

      updateDebtDetailAmount(submitBean).then(res => {
        if (res.success) {
          this.$Notice.success({ desc: '金额调整成功！' })
          this.modalChangeMoney = false
          this.loadDebtDetail()
        } else {
          this.$Notice.error({ title: '提交失败', desc: res.errmsg })
        }
      })
    },
    selectionChange (selections) {
      this.selectedIds = selections.map(x => x.id)
    },
    handleBatchSubmitConfirm () {
      this.$Modal.confirm({
        title: '操作确认',
        content: '您确定要批量确认当前金额吗？确认后将不可更改',
        loading: true,
        onOk: () => {
          const confirmBean = {
            debtId: this.debtInfo.debtId,
            ids: this.selectedIds.join(',')
          }

          confirmDebtDetail(confirmBean).then(res => {
            if (res.success) {
              this.$Notice.success({ desc: '批量发起确认成功！' })
              this.loadDebtDetail()
            } else {
              this.$Notice.error({ title: '提交失败', desc: res.errmsg })
            }
          })
          this.$Modal.remove()
        }
      })
    }
  },
  watch: {
    debtId () {
      this.initDetail()
    }
  },
  computed: {
    debtId () {
      return this.$store.state.investmentDebt.debtId
    }
  }
}
</script>
